<template>
  <div class="account-list">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="小程序用户管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 帐号 -->
        <ml-input
          prop="userCodeLike"
          placeholder="请输入小程序账号"
          style="margin-bottom: 0"
          label="帐号:"
          :input-style="{ width: '120px' }"
          v-model="searchData.userCodeLike"
        />
        <!-- 用户 -->
        <ml-input
          prop="userNameLike"
          placeholder="请输入小程序用户名"
          style="margin-bottom: 0"
          label="用户名:"
          :input-style="{ width: '120px' }"
          v-model="searchData.userNameLike"
        />
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
        />
        <!-- 多级下拉框-管理区域 -->
        <!-- <ml-cascader
          prop="manageArea"
          label="管理区域:"
          :options="areaData"
          clearable
          :props="{ multiple: false, label: 'name', value: 'name' }"
          placeholder="请选择管理区域"
          v-model="searchData.manageArea"
          style="margin-bottom: 0; margin-right: 40px"
        /> -->
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 添加账号 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          :showSubmit="false"
          cancelText="添加小程序账号"
          cancelIcon="el-icon-plus"
          cancelType="primary"
          @click-cancel="clickPush"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="账号" prop="userCode" min-width="10%" />
        <el-table-column align="center" label="阿米巴" prop="companyName" min-width="10%" />
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          label="所属项目"
          prop="projectVos"
          min-width="10%"
        >
          <template #default="scope">
            {{ getProjectsString(scope.row.projectVos) }}
          </template>
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="用户名"
          prop="userName"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="职位"
          prop="job"
          min-width="10%"
        />
        <el-table-column align="center" label="手机号" prop="telephone" min-width="13%" />
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          label="管理区域"
          prop="managerArea"
          min-width="10%"
        >
          <template #default="scope">
            {{ getTreeString(scope.row.regions) }}
          </template>
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="角色"
          prop="userType"
          min-width="12%"
        >
          <template #default="scope">
            <span v-if="scope.row.userType === 'engineer'">工程师</span>
            <span v-if="scope.row.userType === 'division_manager'">事业部负责人</span>
            <span v-if="scope.row.userType === 'proprietor'">客户</span>
            <span v-if="scope.row.userType === 'amiba_manager'">阿米巴合伙人</span>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="创建时间" prop="createTime" min-width="15%" /> -->
        <el-table-column align="center" label="状态" prop="state" min-width="10%">
          <template #default="scope">
            {{ scope.row.state === 'Y' ? '正常' : '停用中' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" priop="createById" min-width="40%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-unlock"
              type="primary"
              @click="clickResetPwd(scope.$index, scope.row)"
            >
              重置密码
            </el-button>
            <el-button
              size="mini"
              :icon="scope.row.state === 'Y' ? 'el-icon-lock' : 'el-icon-unlock'"
              :type="scope.row.state === 'Y' ? 'danger' : 'primary'"
              @click="clickState(scope.$index, scope.row)"
            >
              {{ scope.row.state === 'Y' ? '停用' : '恢复' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 帐号状态提示框 -->
    <ml-dialog
      ref="userStateDialogRef"
      :content="userStateData.content"
      @click-submit="submitUserState"
    />
    <!-- 重置密码提示框 -->
    <ml-dialog
      ref="userResetPwdDialogRef"
      :content="resetPwdData.content"
      @click-submit="submitResetPwd"
    />
    <!-- 删除账户提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  getTreeString,
  getProjectsString,
  clearMemoryPageFn,
  setMemoryPageFn
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'AccountListApp',
  setup() {
    const router = useRouter()
    const { commit, dispatch, getters } = useStore()
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      userCodeLike: getMemoryPage.value.searchData.userCodeLike || '',
      userNameLike: getMemoryPage.value.searchData.userNameLike || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      manageArea: getMemoryPage.value.searchData.manageArea || []
    })

    // 单位配置
    const unitOptions = ref([])
    // 管理区域配置
    const areaData = ref([])
    // 选中单位名
    const getAreaData = id => {
      return (
        unitOptions.value.filter(item => id === item.companyId).map(item => item.regions)[0] || []
      )
    }
    // 监听单位
    watch(
      () => searchData.manageArea,
      () => {
        tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
      }
    )
    // 监听管辖
    watch(
      () => searchData.companyId,
      newvalue => {
        tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
        areaData.value = getAreaData(newvalue)
      }
    )
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取账户列表
    const getUserTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['manageArea'])
      if (searchData.manageArea && searchData.manageArea.length > 0) {
        searchDataParams.province = searchData.manageArea[0]
        searchDataParams.city = searchData.manageArea[1]
        searchDataParams.area = searchData.manageArea[2]
      }
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getUserTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 状态提示框
    const userStateDialogRef = ref()
    const userStateData = reactive({
      content: ''
    })
    // 点击状态按钮
    const clickState = (index, row) => {
      userStateData.content =
        row.state === 'Y' ? '确定停用该小程序账户？' : '确定恢复该小程序账户？'
      userStateData.state = row.state === 'Y' ? 'N' : 'Y'
      userStateData.userId = row.userId
      userStateDialogRef.value.showDialog = true
    }
    // 确定修改状态
    const submitUserState = () => {
      const { state, userId } = userStateData
      dispatch('fetchUpdateAppUser', { state, userId }).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          userStateDialogRef.value.showDialog = false
          getUserTabberData()
        }
      })
    }
    // 添加账户
    const clickPush = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/userAppAction' })
    }
    // 修改账户
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/userAppAction', query: { userId: row.userId } })
    }
    // 删除账户
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该小程序账户？'
    })
    const clickDelete = (index, row) => {
      deleteUserData.userId = row.userId
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { userId } = deleteUserData
      dispatch('fetchDeleteAppUser', userId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }
    // 重置账号密码
    const userResetPwdDialogRef = ref()
    const resetPwdData = reactive({
      content: '确定重置该小程序帐号密码？'
    })
    const clickResetPwd = (index, row) => {
      resetPwdData.userId = row.userId
      userResetPwdDialogRef.value.showDialog = true
    }
    // 确定重置密码
    const submitResetPwd = () => {
      const { userId } = resetPwdData
      dispatch('fetchResetAppUserPass', userId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          userResetPwdDialogRef.value.showDialog = false
          getUserTabberData()
        }
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []

      getUserTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickState,
      userStateDialogRef,
      userStateData,
      submitUserState,
      clickPush,
      clickUpdate,
      clickDelete,
      clickResetPwd,
      resetPwdData,
      userResetPwdDialogRef,
      submitResetPwd,
      deleteUserData,
      deleteUserDialogRef,
      submitDeleteUser,
      areaData,
      unitOptions,
      getTreeString,
      getProjectsString
    }
  }
}
</script>

<style lang="scss" scoped>
.account-list {
  @extend %params-global;
}
</style>
